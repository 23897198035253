import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import mainSettigns from "../../site/settings/main.json"
import contactInfo from "../../site/settings/contact_info.json"
import styled from "styled-components"
import gsap from "gsap"

import BurgerIcon from "../components/burgerIcon"

const LinkStyles = `
position: relative;

&::after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: -3px;
  width: 0;
  height: 1px;
  background-color: var(--black);
  transition: width ease-in-out 0.2s;
}

&:hover {
  &::after {
    width: 100%;
  }
}

&.active {
  &::after {
    width: 100%;
    height: 2px;
  }
}`

const NavBar = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--nav-height);
  width: 100%;
  padding: 0 5%;
  border-bottom: 1px solid var(--black);
  background-color: var(--white);
  transition: top ease-in-out 0.2s 0.05s;
  z-index: 1;
  max-height: var(--nav-height);
  min-height: var(--nav-height);
  visibility: hidden;

  &.hidden {
    top: calc(-1 * (var(--nav-height)));
    transition-delay: 0;
  }
`
const NavLinks = styled.ul`
  li:not(:last-child) {
    margin-bottom: 4rem;
  }

  @media only screen and (min-width: 62em) {
    li:not(:last-child) {
      margin-right: 6rem;
      margin-bottom: 0;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const LogoImg = styled.img`
  width: 40vw;
  max-width: 20rem;
  min-width: 12rem;
`

const StyledAniLink = styled(AniLink)`
  ${LinkStyles}
`

const RegularLink = styled.a`
  ${LinkStyles}
`

const Copyright = styled.p`
  font-size: 1.2rem;
  font-family: var(--fontFamily-titles);
  text-transform: uppercase;
  margin-left: 2.5vw;
`

const StyledFooter = styled.footer`
  padding: 3.5rem 0;
  height: var(--footer-height);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 75em) {
    flex-direction: row;
  }

  address {
    margin: 1rem 0 2rem;
  }
`

const BurgerButton = styled.button`
  background: none;
  border: none;
  outline: none;
  position: absolute;
  right: 2.5rem;
  top: 2.5rem;
  z-index: 1;

  @media only screen and (min-width: 62em) {
    display: none;
  }
`

const NavLink = ({
  name,
  location,
  type = "",
  scrollToHash = null,
  destroyLocoScroll = null,
}) => {
  const pathname =
    type === "scroll"
      ? `#${name.toLowerCase().split(" ").join("_")}`
      : `/${name.toLowerCase().split(" ").join("_")}`

  return (
    <li className="navigation">
      {type === "scroll" ? (
        <RegularLink href={pathname} onClick={scrollToHash}>
          {name}
        </RegularLink>
      ) : (
        <StyledAniLink
          cover
          direction="right"
          bg="#fdfdfe"
          duration={1}
          className={location.pathname.includes(pathname) ? `active` : ``}
          to={pathname}
          onClick={destroyLocoScroll}
        >
          {name}
        </StyledAniLink>
      )}
    </li>
  )
}

const Layout = ({
  location,
  title,
  children,
  className,
  locoScroll,
  addLocomotiveScripts = null,
}) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const [isNavVisible, setIsNavVisible] = useState(true)
  const [isStartAnimationFinished, setIsStartAnimationFinished] = useState(false)
  let lastScrollPosition = useRef(0)
  let navBarRef = useRef(null)
  let logoRef = useRef(null)

  const scrollToHash = e => {
    e.preventDefault()
    toggleMenu(false)

    const hash = new URL(e.target.href).hash

    locoScroll &&
      locoScroll.scrollTo(document.querySelector(hash), { duration: 0.5 })
    // navigate(e.target.href)
  }

  const toggleMenu = (value = null) => {
    if (menuTl.reversed() && value !== false) {
      gsap.set("body", { overflow: "hidden", height: "100vh" })
    } else {
      gsap.set("body", { overflow: "visible", height: "unset" })
    }

    menuTl.reversed(!menuTl.reversed())
  }

  const restertMenuAnimation = () => {
    menuTl.time(0).reverse()
  }

  const initLocomotiveScroll = () => {
    setIsStartAnimationFinished(true);
    if (window.LocomotiveScroll && !locoScroll && addLocomotiveScripts) {
      addLocomotiveScripts()
    }
  }

  const [menuTl] = useState(
    gsap.timeline({ paused: true, defaults: { ease: "back" } })
  )
  const [navTl] = useState(gsap.timeline({ defaults: { ease: "power2" } }))


  const handleLocomotiveInject = ({ scriptTags }) => {
    if (scriptTags && !window.LocomotiveScroll) {
      const scriptTag = scriptTags[0]
      addLocomotiveScripts && (scriptTag.onload = () => {
        (!locoScroll && isStartAnimationFinished) && addLocomotiveScripts()
      })
    }
  }

  const destroyLocoScroll = () => {
    gsap.set('body', { maxHeight: "100vh", overflow: 'hidden' })
    locoScroll && locoScroll.destroy()
  }

  useEffect(() => {
    if (locoScroll) {
      locoScroll.on("scroll", ({ scroll }) => {
        if (lastScrollPosition.current < scroll.y && scroll.y > 0) {
          setIsNavVisible(false)
        } else if (scroll.y <= 0 || lastScrollPosition.current > scroll.y) {
          setIsNavVisible(true)
        }

        lastScrollPosition.current = scroll.y
      })

      window.addEventListener("popstate", destroyLocoScroll)
    }

    return () => {
      window.removeEventListener("popstate", destroyLocoScroll)
    }
  }, [locoScroll])

  useEffect(() => {
    menuTl
      .to(logoRef.current, {
        opacity: 0,
        x: -5,
        display: "none",
        duration: 0.2,
      })
      .to("#middle-bar", { opacity: 0, duration: 0.1 }, 0)
      .to("#bottom-bar", { rotate: -45, y: 1, x: 2, duration: 0.2 }, 0)
      .to("#top-bar", { rotate: 45, x: 3, duration: 0.2 }, 0)
      .to(navBarRef.current, {
        minHeight: "101vh",
        justifyContent: "center",
        duration: 0.2,
      })
      .to(".navigation", {
        opacity: 1,
        x: -10,
        duration: 0.2,
        stagger: 0.1,
        autoAlpha: 1,
      })
      .reverse()
    // .from(
    //   navBarRef.current,
    //   {
    //     autoAlpha: 0,
    //     opacity: 1,
    //     scaleY: 0,
    //     duration: 0.4,
    //     transformOrigin: "center top",
    //   },
    //   "<0.3"
    // )

    navTl
      .set(navBarRef.current, { transformOrigin: "center top" })
      .fromTo(
        navBarRef.current,
        { scaleY: 0, autoAlpha: 0 },
        { scaleY: 1, autoAlpha: 1, duration: 0.3 }
      )
      .delay(0.5)
      .eventCallback("onComplete", initLocomotiveScroll)

    return () => {
      // if (document.body?.style?.overflow) {
      //   gsap.set("body", { overflow: "visible", height: "100vh" })
      // }
    }
  }, [])

  return (
    <div data-is-root-path={isRootPath}>
      {handleLocomotiveInject && (
        <Helmet
          script={[
            {
              src:
                "//cdn.jsdelivr.net/npm/locomotive-scroll@4.0.6/dist/locomotive-scroll.min.js",
            },
          ]}
          onChangeClientState={(newState, addedTags) =>
            handleLocomotiveInject(addedTags)
          }
        />
      )}
      <NavBar
        className={`header-nav ${!isNavVisible ? "hidden" : ""}`}
        ref={navBarRef}
      >
        <div ref={logoRef}>
          <AniLink
            direction="right"
            cover
            bg="#fdfdfe"
            to="/"
            duration={1}
            onClick={destroyLocoScroll}
          >
            <LogoImg
              src={mainSettigns.logo}
              alt="Logo Bui / Pedowska Architekci"
            />
          </AniLink>
        </div>
        <h1 className="hidden"> Bui / Pędowska Architekci </h1>
        <BurgerButton type="button" onClick={toggleMenu}>
          <BurgerIcon />
        </BurgerButton>
        <nav>
          <NavLinks
            onClick={event => event.target.hash && restertMenuAnimation()}
            destroyLocoScroll={destroyLocoScroll}
          >
            <NavLink
              name="Projekty"
              location={location}
              destroyLocoScroll={destroyLocoScroll}
            />
            <NavLink
              name="O nas"
              location={location}
              destroyLocoScroll={destroyLocoScroll}
            />
            <NavLink
              name="Kontakt"
              type="scroll"
              location="#contact"
              scrollToHash={scrollToHash}
            />
          </NavLinks>
        </nav>
      </NavBar>
      <div data-scroll-container className={className}>
        <main>{children}</main>
        <StyledFooter>
          <Copyright>&copy; Bui-Pędowska, 2020</Copyright>
          <div className="container">
            <div className="row">
              <div className="col-xs-6 col-md-4 col-lg-2">
                <p className="additional-info">Biuro</p>
                <address className="small-text">
                  <a
                    href={contactInfo.adress.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    ul. {contactInfo.adress.street} {contactInfo.adress.number}
                    <br />
                    {contactInfo.adress.postal_code} {contactInfo.adress.city}
                  </a>
                </address>
              </div>
              <div className="col-xs-6 col-md-4 col-lg-2">
                <p className="additional-info">Kontakt</p>
                <address className="small-text">
                  <a href={`mailto:${contactInfo.email}`}>
                    {contactInfo.email}
                  </a>
                  <br />
                  <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
                </address>
              </div>
            </div>
          </div>
        </StyledFooter>
      </div>
    </div>
  )
}

export default Layout
